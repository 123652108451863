<template>
  <div class="the-survey">
    <TheModal :is-open="isOpen" :title="surveyTitle" custom-classes="survey-dialog">
      <form @submit.prevent="submit">
        <div class="survey-container">
          <div class="survey-description">
            <p
              v-if="description"
              :class="{ 'hide-on-mobile': hideDescriptionOnMobile }"
              v-html="description"
            ></p>
            <p v-if="title && isPaged && questionsToShow.length > 1">
              {{ $t('core.survey.step_of', { current: currentPage + 1, overall: questionsToShow.length }) }}
            </p>
          </div>
          <div
            v-for="(question, index) of questionsToShow"
            :key="index"
            class="survey-question"
            :class="{ hidden: isPaged && index !== currentPage, bordered: title }"
          >
            <p v-if="title" v-html="processText(question.text)" />
            <div
              v-for="(answer, aIndex) of question.answers"
              :key="aIndex"
              class="survey-answer"
              :class="{ 'bigger-font': !title }"
            >
              <div v-if="question.multiple" class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    :name="question.key"
                    :value="answer.value"
                    v-model="multipleAnswers[question.key]"
                    @change="multiQuestionAnswered(question.key)"
                  />
                  {{ processText(answer.text) }}
                </label>
              </div>
              <div v-else-if="answer.type && answer.type === 'numeric'">
                <input
                    required
                    type="radio"
                    :name="question.key"
                    :value="answer.value"
                    checked="checked"
                />
                <span v-if="answer.prefix" v-text="answer.prefix"></span>
                <input
                  type="number"
                  class="form-control input-inline"
                  :name="question.key"
                  @input="questionAnswered(question.key, $event.target.value)"
                />
                <span v-if="answer.suffix" v-text="answer.suffix"></span>
              </div>
              <div v-else-if="!answer.value" class="text form-group">
                <label>
                  <input
                    required
                    type="radio"
                    :name="question.key"
                    :value="answer.value"
                    checked="checked"
                  />
                  <input
                    type="text"
                    class="form-control"
                    :name="question.key"
                    @input="questionAnswered(question.key, $event.target.value)"
                  />
                </label>
              </div>
              <div v-else class="radio">
                <label>
                  <input
                    required
                    type="radio"
                    :name="question.key"
                    :value="answer.value"
                    @input="
                      questionAnswered(question.key, answer.value, !!question.finishSurvey, !title)
                    "
                  />
                  {{ processText(answer.text) }}
                </label>
              </div>
            </div>
            <div v-if="question.numeric" class="survey-answer">
              <input
                type="number"
                class="form-control"
                :name="question.key"
                :placeholder="question.placeholder"
                @input="questionAnswered(question.key, $event.target.value)"
              />
            </div>
            <div v-else-if="question.placeholder" class="survey-answer">
              <textarea
                class="form-control"
                :placeholder="question.placeholder"
                @input="questionAnswered(question.key, $event.target.value)"
              />
            </div>
            <button
              v-if="
                !submitSuccess && isPaged && currentPage !== questionsToShow.length - 1 && title
              "
              class="buy_now_button"
              :class="{ disabled: nextButtonDisabled }"
              :disabled="nextButtonDisabled"
              @click.prevent="nextPage"
            >
              {{ $t('core.survey.next_question') }}
            </button>
          </div>
          <div class="survey-submit">
            <div v-if="validationError" class="text-danger">{{ $t('core.survey.answer_all') }}</div>
            <div v-if="networkError" class="text-danger">{{ $t('core.survey.error') }}</div>
            <button
              v-if="!submitSuccess && (!isPaged || currentPage === questionsToShow.length - 1)"
              class="buy_now_button"
              :class="{ disabled: submitting }"
              @submit.prevent="submit"
            >
              {{ submitting ? $t('core.survey.submitting') : $t('core.survey.submit') }}
            </button>
            <p v-if="submitSuccess" class="text-success">
              <strong>{{ $t('core.survey.thank_you') }}</strong>
            </p>
          </div>
        </div>
      </form>
    </TheModal>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { mapState } from "pinia";
import { useSurveyStore } from "~/stores/survey";
import { useMainStore } from "~/stores/main";

export default {
  props: {
    titleSuffix: {
      type: String,
      default: '',
    },
    hideDescriptionOnMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: false,
      currentPage: 0,
      nextButtonDisabled: true,
      validationError: false,
      multipleAnswers: {},
    };
  },
  computed: {
    questionsToShow() {
      return this.questions.filter(question => {
        if (!question.show_conditions) {
          return true;
        }
        return question.show_conditions.every(
          condition => condition.values.indexOf(this.answers[condition.question]) > -1,
        );
      });
    },
    surveyTitle() {
      return this.title
        ? this.title + this.titleSuffix
        : this.processText(this.questionsToShow[this.currentPage]?.text);
    },
    ...mapState(useSurveyStore, {
      isOpen: 'isOpen',
      networkError: 'networkError',
      submitSuccess: 'submitSuccess',
      submitting: 'submitting',
      isPaged: 'isPaged',
      surveyType: 'currentType',
      title: 'title',
      description: 'description',
      questions: 'questions',
      answers: 'answers',
    }),
  },
  watch: {
    questionsToShow(newValues, oldVaues) {
      // nextTick(() => {
      //   setTimeout(() => {
      //     this.selected = false;
      //   });
      // });
    },
  },
  mounted() {
    // if (!this.$device.isMobileOrTablet) {
    //   useSurveyStore().isPaged = false;
    // }

    this.questions.forEach(q => {
      if (q.multiple) {
        this.multipleAnswers[q.key] = [];
      }
    });
  },
  methods: {
    nextPage() {
      this.currentPage += 1;
      this.nextButtonDisabled = true;
    },
    questionAnswered(key, value, finishSurvey = false, nextQuestion = false) {
      this.nextButtonDisabled = false;
      useSurveyStore().setAnswer({ key: key, answer: value });

      if (finishSurvey) {
        this.submit(true);
      } else if (nextQuestion) {
        this.nextPage();
      }
    },
    multiQuestionAnswered(key) {
      const answer =
        this.multipleAnswers[key] && Array.isArray(this.multipleAnswers[key])
          ? this.multipleAnswers[key].join(',')
          : '';

      if (answer) {
        this.nextButtonDisabled = false;
        this.validationError = false;
        useSurveyStore().setAnswer({ key: key, answer: value });
      } else {
        this.nextButtonDisabled = true;
      }
    },
    random(answers) {
      return [
        ...answers.slice(0, answers.length - 1).sort(() => 0.5 - Math.random()),
        answers[answers.length - 1],
      ];
    },
    async submit(forceSubmit = false) {
      let allAnswersFilled = true;

      this.questions.forEach(q => {
        if (
          q.multiple &&
          (!this.multipleAnswers[q.key] || this.multipleAnswers[q.key].length === 0)
        ) {
          allAnswersFilled = false;
        } else if (!this.answers[q.key]) {
          allAnswersFilled = false;
        }
      });

      if (!forceSubmit && !allAnswersFilled) {
        this.validationError = true;
        return;
      }

      this.validationError = false;

      await useSurveyStore().submitSurvey();
    },
    processText(text) {
      if (!text && this.surveyType === 'did_you_find_what_you_need_oct_2021') {
        return this.answers.q1 === 'yes'
          ? 'Great! How can we improve the page?'
          : 'Sorry to hear that - could you tell us what was missing?';
      }

      return useMainStore().countryCode === 'US' && text ? text.replace('football', 'soccer') : text;
    },
  },
};
</script>

<style lang="scss" scoped>
.the-survey {
  .survey-container {
    .survey-question {
      &.bordered {
        border-top: solid 1px #ccc;
        margin-top: 10px;
        padding-top: 10px;
      }
    }
    .survey-description {
      .hide-on-mobile {
        @media (max-width: $break_md_under) {
          display: none;
        }
      }
    }
    .survey-answer {
      display: block;
      vertical-align: top;

      @media (min-width: $break-md-upper) {
        width: calc(100% - 87px);
      }

      .radio,
      .checkbox {
        margin-top: 2px;
        margin-bottom: 2px;
      }
      .text.form-group {
        margin-bottom: 0;
        .form-control {
          line-height: 1;
          padding: 4px 8px;
        }
      }
      label {
        font-weight: 300;
        padding: 0;
        min-height: 14px;
        //font-size: 23px;

        input {
          visibility: visible;
          display: inline-block !important;
          height: auto;
          width: auto;
          position: relative;
          margin-left: 0;
          //height: 18px;
          //width: 18px;
          //margin: 7px 0 0;
        }

        input[type='radio'] {
          &:invalid,
          &:required {
            box-shadow: none !important;
          }
        }
      }
      .input-inline {
        display: inline-block;
        max-width: 65px;
      }
      textarea {
        width: 100%;
        border: 2px solid $grey;
        height: 64px;
        padding: 10px 8px;
      }
      .error {
        color: $red;
      }
      &.bigger-font {
        label {
          font-size: 20px;
          min-height: 24px;
        }
      }
    }
    button {
      display: inline-block;
      margin-top: 15px;
    }
    .survey-submit {
      .text-success,
      .text-danger {
        margin-top: 12px;
      }
    }
  }
  .survey-buttons {
    button {
      border-radius: 2px !important;
      margin-bottom: 10px;
    }
  }

  :deep() {
    .survey-dialog {
      .rr-modal-dialog {
        padding: 30px 15px 25px 30px !important;
        max-width: 600px !important;
        width: 600px !important;
        top: 15% !important;
        left: calc(50% - 300px) !important;

        @media (max-width: 630px) {
          max-width: 400px !important;
          width: 400px !important;
          left: calc(50% - 200px) !important;
        }

        @media (max-width: 430px) {
          max-width: 360px !important;
          width: 360px !important;
          left: calc(50% - 180px) !important;
        }

        @media (min-width: $break_sm_upper) {
          .rr-modal-dialog-content {
            max-height: 580px;
          }

          .unbreakable {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
